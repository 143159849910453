@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: white;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background: white;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  width: 15px;
  background: #307aba;
}
body::-webkit-scrollbar-thumb {
  width: 15px;
  border-radius: 60px;
  background: #3b1e8a;
}

code {
  font-family: "Roboto", sans-serif;
}
.ant-carousel .slick-dots {
  z-index: 0 !important;
}
